<template>
  <div class="container-fluid m-0 shadow p-4 bg-white rounded mb-4">
    <Productgeneral />
    <Productvideos />
    <Productimage class="mt-5" />
    <Productdescription class="mt-5" />
    <Productlongdescription class="mt-5" />
    <Productremark class="mt-5" />
  </div>
</template>

<script>
import Productgeneral from "@/components/Productdetail/Edit/Productgeneral.vue";
import Productvideos from "@/components/Productdetail/Edit/Productvideos.vue";
import Productdescription from "@/components/Productdetail/Edit/Productdescription.vue";
import Productlongdescription from "@/components/Productdetail/Edit/Productlongdescription.vue";
import Productremark from "@/components/Productdetail/Edit/Productremark.vue";
import Productimage from "@/components/Productdetail/Edit/Productimage.vue";
export default {
  name: "Productedit",
  components: {
    Productgeneral,
    Productvideos,
    Productdescription,
    Productlongdescription,
    Productremark,
    Productimage,
  },
};
</script>

<style>
</style>