<template>
  <div class="container-fluid w-100 m-0">
    <!-- ---------- -->
    <p class="mainfont">Product Remark</p>
    <hr />
    <div class="row">
      <div class="col-12 col-xl-6">
        <p class="badge badge-info">TH</p>
        <tinymce
          class="shadow bg-white rounded"
          id="remarkTH"
          :plugins="plugins"
          :toolbar="toolbar"
          :other_options="options"
        ></tinymce>
      </div>
      <div class="col-12 col-xl-6">
        <p class="badge badge-info">EN</p>
        <tinymce
          class="shadow bg-white rounded"
          id="remarkEN"
          :plugins="plugins"
          :toolbar="toolbar"
          :other_options="options"
        ></tinymce>
      </div>
    </div>
  </div>
</template>

<script>
import tinymce from "vue-tinymce-editor";
export default {
  name: "Productremark",
  components: { tinymce },
  data() {
    return {
      // Tinymce Editor
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons ",
      options: {
        height: 150,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },

      // End Tinymce Editor
    };
  },
  mounted() {},
  methods: {},
};
</script>

 <style scoped>
.mce-panel {
  /* border: 0 solid #f3f3f3; */
  /* border: 0 solid #c5c5c5; */
  background-color: #fff !important;
}
</style>