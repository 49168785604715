<template>
  <div class="container-fluid w-100 m-0">
    <!-- ---------- -->
    <p class="mainfont">วิดีโอ</p>
    <hr />
    <div class="row justify-content-center">
      <div class="col-12 col-xl-6">
        <div class="row shadow m-0 p-3 mb-2 bg-white rounded">
          <div class="col-8">
            <div class="input-group input-group-sm">
              <input
                type="text"
                class="form-control"
                placeholder="ลิงค์วีดีโอ youtube"
                v-model="LinkVideo"
              />
            </div>
          </div>
          <div class="col-4">
            <Buttonnew
              @click.native="addvideo()"
              key="AddVideoProduct"
              title="Add Video"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <draggable
          v-model="videolist"
          :move="checkMove"
          @start="drag = true"
          @end="drag = false"
          handle=".handle"
          @change="change"
        >
          <div
            class="row shadow m-0 p-3 mb-2 bg-white rounded"
            v-for="(e, i) in videolist"
            :key="i"
          >
            <div class="col-1">
              <span
                v-if="!e.fixed"
                class="handle"
                type="button"
                v-html="icon.move"
              ></span>
            </div>

            <div class="col-9">
              <div class="row">
                <div class="col-2">
                  <p class="mr-2 badge badge-primary">{{ e.index }}</p>
                </div>
                <div class="col-10">
                  <iframe class="video" :src="e.link"></iframe>
                </div>
              </div>
            </div>
            <div class="col-1">
              <span
                type="button"
                v-if="e.fixed"
                v-html="icon.fixed"
                @click="setfixed(e)"
              ></span
              ><span
                type="button"
                v-else
                v-html="icon.unfixed"
                @click="setfixed(e)"
              ></span>
            </div>
            <div class="col-1">
              <span
                type="button"
                v-html="icon.remove"
                @click="remove(e)"
              ></span>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Buttonnew from "@/components/common/Buttonnew.vue";
export default {
  name: "Productvideos",
  components: {
    draggable,
    Buttonnew,
  },
  data() {
    return {
      icon: {
        move: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrows-move" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"/>
</svg>`,
        unfixed: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pin-angle-fill" viewBox="0 0 16 16">
  <path d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146z"/>
</svg>`,
        fixed: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pin-fill" viewBox="0 0 16 16">
  <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354z"/>
</svg>`,
        remove: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dc3545" class="bi bi-trash-fill" viewBox="0 0 16 16">
  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
</svg>`,
      },
      videolist: [
        {
          index: 0,
          link: "https://www.youtube.com/embed/CGsghjg3Vl8",
          fixed: false,
        },
        {
          index: 1,
          link: "https://www.youtube.com/embed/oi3WVqhmN-Q",
          fixed: false,
        },
        {
          index: 2,
          link: "https://www.youtube.com/embed/uz5-Uid9974",
          fixed: false,
        },
      ],
      LinkVideo: "",
    };
  },
  mounted() {},
  methods: {
    addvideo() {
      try {
        if (this.LinkVideo == "") {
          throw "กรุณาระบุ Link วิดีโอ";
        }

        let data = {
          index: this.videolist.length,
          link:
            "https://www.youtube.com/embed/" +
            this.LinkVideo.split("v=")[1].split("&")[0],
          fixed: false,
        };

        this.videolist.push(data);
        this.LinkVideo = "";
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    handleClick(e) {
      console.log(e);
    },
    remove(e) {
      let index = this.videolist.findIndex((i) => i == e);
      if (index != -1) {
        if (confirm(`Do you really want to delete ${e.name}?`)) {
          this.videolist.splice(index, 1);
          this.change();
        }
      }
    },
    change() {
      for (var i in this.videolist) {
        this.videolist[i].index = i;
      }
    },
    checkMove(e) {
      return this.isDraggable(e.draggedContext);
    },
    isDraggable(context) {
      const { index, futureIndex } = context;
      return !(
        this.videolist[index].fixed || this.videolist[futureIndex].fixed
      );
    },
    setfixed(e) {
      let index = this.videolist.findIndex((i) => i == e);
      if (index != -1) {
        this.videolist[index].fixed = !this.videolist[index].fixed;
      }
    },
  },
};
</script>

 <style scoped>
.video {
  border-radius: 10px;
  height: 15vh;
  width: 100%;
}
</style>