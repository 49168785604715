<template>
  <div class="container-fluid m-0">
    <!-- ---------- -->
    <p class="mainfont">ข้อมูลทั่วไป</p>
    <hr />
    <div class="row align-items-center">
      <div class="col-12 col-xl-2 fontform my-1 my-xl-3">ชื่อสินค้า</div>
      <div class="col-12 col-xl-3">
        <div class="input-group input-group-sm my-1 my-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text">TH</span>
          </div>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-12 col-xl-3">
        <div class="input-group input-group-sm my-1 my-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text">EN</span>
          </div>
          <input type="text" class="form-control" />
        </div>
      </div>
    </div>
    <!-- ---------- -->
    <div class="row align-items-center">
      <div class="col-12 col-xl-2 fontform my-1 my-xl-3">Promotext</div>
      <div class="col-12 col-xl-3">
        <div class="input-group input-group-sm my-1 my-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text">TH</span>
          </div>
          <textarea class="form-control"></textarea>
        </div>
      </div>
      <div class="col-12 col-xl-3">
        <div class="input-group input-group-sm my-1 my-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text">EN</span>
          </div>
          <textarea class="form-control"></textarea>
        </div>
      </div>
    </div>
    <!-- ---------- -->
    <div class="row align-items-center">
      <div class="col-12 col-xl-2 fontform my-1 my-xl-3">Title</div>
      <div class="col-12 col-xl-3">
        <div class="input-group input-group-sm my-1 my-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text">TH</span>
          </div>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-12 col-xl-3">
        <div class="input-group input-group-sm my-1 my-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text">EN</span>
          </div>
          <input type="text" class="form-control" />
        </div>
      </div>
    </div>
    <!-- ---------- -->
    <div class="row align-items-center">
      <div class="col-12 col-xl-2 fontform my-1 my-xl-3">Keywords</div>
      <div class="col-12 col-xl-3">
        <div class="input-group input-group-sm my-1 my-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text">TH</span>
          </div>
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-12 col-xl-3">
        <div class="input-group input-group-sm my-1 my-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text">EN</span>
          </div>
          <input type="text" class="form-control" />
        </div>
      </div>
    </div>
    <!-- ---------- -->
    <div class="row align-items-center">
      <div class="col-12 col-xl-2 fontform my-1 my-xl-3">Description</div>
      <div class="col-12 col-xl-3">
        <div class="input-group input-group-sm my-1 my-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text">TH</span>
          </div>
          <textarea class="form-control"></textarea>
        </div>
      </div>
      <div class="col-12 col-xl-3">
        <div class="input-group input-group-sm my-1 my-xl-3">
          <div class="input-group-prepend">
            <span class="input-group-text">EN</span>
          </div>
          <textarea class="form-control"></textarea>
        </div>
      </div>
    </div>
    <!-- --------------- -->
    <div class="row">
      <div class="col-12 col-xl-6 mb-1 mb-xl-3">
        <p class="fontform">Show On Web</p>
        <div class="row px-4">
          <b-table
            :fields="web.fields"
            :items="web.items"
            striped
            small
            sticky-header
            outlined
            bordered
            style="font-size: 0.8em"
            class="overflow-tb"
          >
            <template #head(check)="">
              <b-form-checkbox
                v-model="isSelectAllweb"
                @change="onSelectAllweb($event)"
              ></b-form-checkbox>
            </template>

            <template #cell(check)="row">
              <b-form-checkbox
                @change="onSelectCheckboxweb()"
                v-model="row.item.check"
              ></b-form-checkbox>
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </div>
      </div>
      <div class="col-12 col-xl-6 mb-1 mb-xl-3">
        <p class="fontform">Status</p>
        <div class="row px-4">
          <b-table
            :fields="status.fields"
            :items="status.items"
            striped
            small
            sticky-header
            outlined
            bordered
            style="font-size: 0.8em"
            class="overflow-tb"
          >
            <template #head(check)="">
              <b-form-checkbox
                v-model="isSelectAllstatus"
                @change="onSelectAllstatus($event)"
              ></b-form-checkbox>
            </template>

            <template #cell(check)="row">
              <b-form-checkbox
                @change="onSelectCheckboxstatus()"
                v-model="row.item.check"
              ></b-form-checkbox>
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Productgeneral",
  data() {
    return {
      isSelectAllweb: false,
      isSelectAllstatus: false,
      web: {
        fields: [
          {
            key: "check",
            label: "",
            thStyle: { width: "10%", textAlign: "center" },
            tdClass: "text-center",
          },
          {
            key: "index",
            label: "#",
            thStyle: { width: "10%", textAlign: "center" },
            tdClass: "text-center",
          },
          {
            key: "name",
            label: "เว็บไซต์",
            thStyle: { textAlign: "center" },
            tbClass: "bTableThStyle",
          },
        ],
        items: [
          { value: "TWD", name: "TWD", check: true },
          { value: "BNB", name: "BNB", check: true },
        ],
      },

      status: {
        fields: [
          {
            key: "check",
            label: "",
            thStyle: { width: "10%", textAlign: "center" },
            tdClass: "text-center",
          },
          {
            key: "index",
            label: "#",
            thStyle: { width: "10%", textAlign: "center" },
            tdClass: "text-center",
          },
          {
            key: "name",
            label: "สถานะ",
            thStyle: { textAlign: "center" },
          },
        ],
        items: [
          { value: "Visible", name: "Visible", check: true },
          {
            value: "InstallationService",
            name: "InstallationService",
            check: false,
          },
          {
            value: "InstallmentPayment",
            name: "InstallmentPayment",
            check: true,
          },
        ],
      },
    };
  },
  mounted() {
    this.onSelectCheckboxweb();
    this.onSelectCheckboxstatus();
  },
  methods: {
    //   --------------- status --------------
    onSelectCheckboxstatus() {
      let obj = this.status.items;
      var a = obj.filter(function (el) {
        return el.check === true;
      });

      this.isSelectAllstatus = a.length == obj.length;
    },
    onSelectAllstatus(e) {
      let obj = this.status.items;
      if (e) {
        Object.keys(obj).forEach((key) => {
          obj[key].check = true;
        });
      } else {
        Object.keys(obj).forEach((key) => {
          obj[key].check = false;
        });
      }
    },
    //   ----------------- web --------------
    onSelectCheckboxweb() {
      let obj = this.web.items;
      var a = obj.filter(function (el) {
        return el.check === true;
      });

      this.isSelectAllweb = a.length == obj.length;
    },
    onSelectAllweb(e) {
      let obj = this.web.items;
      if (e) {
        Object.keys(obj).forEach((key) => {
          obj[key].check = true;
        });
      } else {
        Object.keys(obj).forEach((key) => {
          obj[key].check = false;
        });
      }
    },
  },
};
</script>

 <style scoped>
.overflow-tb {
  width: 100%;
  height: 30vh;
}
</style>