<template>
  <div class="container-fluid p-2 p-xl-4 bfont m-0">
    <div class="row mx-0 mb-4 p-0">
      <div class="col m-0 p-0 mainfont">{{pagename}}</div>
      <div class="col m-0 p-0 text-right">
        <Buttonedit
          v-if="mode != 'edit'"
          @click.native="setedit()"
          key="Edit_Product"
          title="Edit Product"
        />
      </div>
    </div>
    <Productedit v-if="mode == 'edit'" />
  </div>
</template>

<script>
import Productedit from "@/components/Productdetail/Productedit.vue";
import Buttonedit from "@/components/common/Buttonedit.vue";
export default {
  name: "Productdetail",
  components: { Productedit, Buttonedit },
  data() {
    return {
      pagename: "PRODUCT DETAIL",
      mode: "view",
    };
  },
  watch: {
    mode: function () {
      if (this.mode == "edit") {
        this.pagename = "EDIT PROCUCT";
      } else {
        this.pagename = "PRODUCT DETAIL";
      }
    },
  },
  mounted() {
    this.initdata();
  },
  methods: {
    async initdata() {
      console.log(this.$route.params.id);
    },
    setedit() {
      this.mode = "edit";
    },
  },
};
</script>

<style>
</style>