<template>
  <button class="btn btn-primary btn-sm bfont">
    <span v-html="icon" class="mr-1"></span>{{ title }}
  </button>
</template>

<script>
export default {
  name: "Buttonnew",
  props: ["title"],
  data() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
</svg>`,
    };
  },
};
</script>

<style>
</style>