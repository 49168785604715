<template>
  <div class="container-fluid w-100 m-0">
    <!-- ---------- -->
    <p class="mainfont">Product Images</p>
    <hr />
    <div class="row">
      <div class="form-group">
        <label>Product image</label>
        <input
          type="file"
          accept="image/png, image/jpeg"
          class="form-control"
          @change="upload($event)"
        />
      </div>
      <UploadImages @changed="handleImages"/>
    </div>
  </div>
</template>

<script>
import UploadImages from "vue-upload-drop-images";
export default {
  name: "Productimage",
  components: {
    UploadImages,
  },
  data() {
    return {
      img: "",
    };
  },
  mounted() {},
  methods: {
    async upload(event) {
      let _this = this;
      this.img = event.target.files[0];
      console.log(this.img);
    },
    handleImages(files) {
      console.log(files);
    },
  },
};
</script>

 <style scoped>
</style>